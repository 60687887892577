/* export const BASE_URL = 'https://api.global.jellyride-prod.app/api'
export const BASE_URL1 = 'https://api.global.jellyride-prod.app/webapi'
export const BASE_MEDIA = 'https://api.global.jellyride-prod.app/media'
export const BASE_PUBLIC = 'https://api.global.jellyride-prod.app/public'
export const BASE_PAYMENT = 'https://api.global.jellyride-prod.app/payment'
export const BASE_STORAGE = 'https://cdn.global.jellyride-prod.app' */

export const BASE_URL = 'https://jr-prod-manager-d0cd2253.azurewebsites.net/api'
export const BASE_URL1 = 'https://jr-prod-webapp-api-ed002e70.azurewebsites.net/webapi'
export const BASE_MEDIA = 'https://jr-prod-webapp-api-ed002e70.azurewebsites.net/media'
export const BASE_PUBLIC = 'https://jr-prod-webapp-api-ed002e70.azurewebsites.net/public'
export const BASE_PAYMENT = 'https://jr-prod-webapp-api-ed002e70.azurewebsites.net/payment'
export const BASE_STORAGE = 'https://jrprodstorage6fb394dd.blob.core.windows.net'

export const BASE_ESHOP = 'https://experience.jellyride.com/store'
export const BASE_REVIEW = 'https://experience.jellyride.com/review'
